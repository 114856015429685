// Generated by CoffeeScript 2.5.1
let api, api_server, del, get, post, simplify_dates, unsimplify_dates, update;

import { auth } from "../../db.js";

api_server = location.origin;

// api_server = "https://vs290.ailove.ru:3030";

// api_server = "http://localhost:3000"
api = `${api_server}/api`;

// API GET
get = async function(method, qs = {}) {
    return await (
        await fetch(`${api}/${method}?${new URLSearchParams(qs)}`)
    ).json();
};

// API POST
post = async function(method, body) {
    let res;
    res = await fetch(`${api}/${method}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(body),
    });
    return await res.json();
};

// API UPDATE
update = async function(method, body) {
    let res;
    res = await fetch(`${api}/${method}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(body),
    });
    return await res.json();
};

// API DELETE
del = async function(method, id) {
    let res;
    res = await fetch(`${api}/${method}?id=${id}`, {
        method: "DELETE",
    });
    return await res.json();
};

// Конвертиурем даты в тексты
simplify_dates = function(t) {
    let k, v;
    for (k in t) {
        v = t[k];
        if (k.slice(0, 4) === "Дата") {
            t[k] = v.slice(0, 10);
        }
    }
    return t;
};

// Конвертируем текстовые даты
unsimplify_dates = function(t) {
    let k, v;
    for (k in t) {
        v = t[k];
        if (!(k.slice(0, 4) === "Дата")) {
            continue;
        }
        t[k] = new Date(v);
        if (isNaN(t[k])) {
            t[k] = "";
        }
    }
    return t;
};

export default {
    api_server: api_server,
    api: api,
    // ЭКШЕНЫ
    actions: {
        // Планы
        fetchPlans: async function(ctx, qs = {}) {
            let plans;
            console.log("fetch plans");
            plans = await get("plans", qs);
            return ctx.commit("updatePlans", plans);
        },
        // Удаление плана
        deletePlan: async function(ctx, plan) {
            let res;
            if (!confirm(`Точно удалить #${plan.id} ${plan.original_name}?`)) {
                return;
            }
            res = await del("plans", plan.id);
            // Удаляем план из списка если ок
            // if res?.ok == 1
            //   # ctx.commit('deletePlan', plan)
            return ctx.dispatch("fetchPlans");
        },
        updatePlan: function(ctx, plan) {},
        // Отправляем XLSM на сервер
        uploadXlsm: function(ctx, { xlsm, name }) {
            return new Promise(function(resolve, reject) {
                let fd, req;
                req = new XMLHttpRequest();
                req.open("POST", `${api}/plan`, true);
                fd = new FormData();
                fd.append(
                    "file",
                    new Blob([xlsm], {
                        type: "application/vnd.ms-excel.sheet.macroEnabled.12",
                    }),
                    name
                );
                fd.append("user", auth.currentUser.email);
                req.onload = function() {
                    if (this.readyState === 4 && this.status === 200) {
                        // ctx.commit "addNewPlan", JSON.parse @response
                        ctx.dispatch("fetchPlans");
                        return resolve(this.response);
                    } else {
                        return reject(this.response);
                    }
                };
                return req.send(fd);
            });
        },
    },
    // МУТАЦИИ
    mutations: {
        updatePlans: function(state, plans) {
            console.log("Update plans");
            console.log(plans);
            return (state.plans = plans);
        },
        deletePlan: function(state, plan) {
            let i;
            i = state.plans.indexOf(plan);
            return state.plans.splice(i, 1);
        },
    },
    // СТЭЙТЫ
    state: {
        plans: [],
    },

    // ГЕТЕРЫ
    getters: {
        allPlans: function(state) {
            return state.plans;
        },
    },
};
