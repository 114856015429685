










import PlanUpload   from "./PlanUpload"
import PlansList    from "./PlansList"

export default

  name: "PlanFact"  
  components: {
      PlanUpload
      PlansList
  }
  data: ->
    tab: null
    selectedPlan: null
  methods:
    onPlanSelect: (plan)->
      @tab = 1
      setTimeout (=>@selectedPlan = plan), 500

