

























import plan from '../store/modules/plan'
import {mapGetters, mapActions} from "vuex"

export default

  name: "PlansList"  

  data: ->
    plans: [],
    planToEdit: null,
    headers: [
      { text: "#"          , value: "id"                          }
      { text: "Хеш шапки"  , value: "header_hash"                 }
      { text: "Название"   , value: "original_name"               }
      # { text: "url"        , value: "original_file"               }
      # { text: "url"        , value: "modified_file"               }
      { text: "Автор"      , value: "created_by"                  }
      { text: "Дата"       , value: "creation_date", type: "date" }
      { text: null, value: 'actions', sortable: false },
    ]

  computed: {
      ...mapGetters(["allPlans"])
      api:-> plan.api_server
  }
  
  methods: {
    ...mapActions(["fetchPlans", "deletePlan"])
    onRowClick: (item)->
      @$emit "planSelected", item
    # editPlan: (plan)-> @planToEdit = plan
  }
  
  mounted: ->
      @fetchPlans()
      console.log "API", plan.api_server

