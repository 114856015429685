




































import {mapActions, mapGetters} from "vuex"

export default

  name: "PlanUpload"

  data: ->
    dialog: false
    alert: false
    loading: false
    file: null
    
  computed: {
    ...mapGetters(["new_plan"])
  }
  
  mounted: ->

  methods: {

    ...mapActions ['uploadXlsm']
     
    upload: ->
        reader = new FileReader()
        reader.addEventListener 'load', (e)=>
            @loading = true
            try
              await @uploadXlsm
                  xlsm: e.target.result
                  name: @file.name
              @close()
            finally
              @loading = false
            
        reader.readAsArrayBuffer @file
    close: ->
        @dialog = false
        @file = null
  }

