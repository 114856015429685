import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/storage'
import 'firebase/auth'

import Vue from 'vue'
// import { firestorePlugin } from 'vuefire'
// Vue.use(firestorePlugin)

var firebaseConfig = {
    apiKey: "AIzaSyAKoJSA1NLYCY0a2tZY_A7Gd44VdImF_IM",
    authDomain: "icom-plan-fact.firebaseapp.com",
    projectId: "icom-plan-fact",
    storageBucket: "icom-plan-fact.appspot.com",
    messagingSenderId: "973186920536",
    appId: "1:973186920536:web:dc7249f55adca6497d1a2d"
};

const app = firebase.initializeApp(firebaseConfig)
// export const db = app.firestore()
// export const storage = app.storage()
export const auth = app.auth()
// export const sts =  firebase.firestore.FieldValue.serverTimestamp
