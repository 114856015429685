import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from "./db";

console.log("router", router);

import Vuetify from "vuetify/lib";
Vue.use(Vuetify);
let vuetify = new Vuetify({});

Vue.config.productionTip = false;

let app;

auth.onAuthStateChanged(() => {
    console.log(auth);
    if (!app)
        app = new Vue({
            router,
            vuetify,
            store,
            render: (h) => h(App),
        }).$mount("#app");
});
